// collapse items start
const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse"]'));
const fnmap = {
    'toggle': 'toggle',
    'show': 'add',
    'hide': 'remove'
};
const collapse = (selector, cmd, elm) => {
    elm.classList[fnmap[cmd]]('show');
    const targets = Array.from(document.querySelectorAll(selector));
    targets.forEach(target => {
        target.classList[fnmap[cmd]]('show');
    });
}
// collapse items end
// start mobile navigation
const mobileNavigation = document.getElementById('mobileNavigation');
const applyModal = document.getElementById('applyModal');
const referModal = document.getElementById('referModal');
const body = document.getElementById('body');
// end mobile navigation

// global click event listener
window.addEventListener('click', (ev) => {
    const elm = ev.target;

    if (triggers.includes(elm)) {
        // if element is collapsable
        const selector = elm.getAttribute('data-target');
        collapse(selector, 'toggle', elm);
    } else if (elm.classList.contains('mobile-navigation-open-btn') || elm.parentElement && elm.parentElement.classList.contains('mobile-navigation-open-btn')) {
        // open mobile navigation
        window.scrollTo(0,0);
        body.classList.add('no-scroll');
        if (elm.classList.contains('mobile-navigation-open-btn')) {
            elm.classList.remove('mobile-navigation-open-btn');
            elm.classList.add('mobile-navigation-close-btn')
        } else {
            elm.parentElement.classList.remove('mobile-navigation-open-btn');
            elm.parentElement.classList.add('mobile-navigation-close-btn');
        }
        mobileNavigation.classList.add('open');
    } else if (elm.classList.contains('mobile-navigation-close-btn') || elm.parentElement && elm.parentElement.classList.contains('mobile-navigation-close-btn')) {
        // close mobile navigation
        body.classList.remove('no-scroll');
        mobileNavigation.classList.remove('open');
        if (elm.classList.contains('mobile-navigation-close-btn')) {
            elm.classList.remove('mobile-navigation-close-btn');
            elm.classList.add('mobile-navigation-open-btn');
        } else {
            elm.parentElement.classList.remove('mobile-navigation-close-btn');
            elm.parentElement.classList.add('mobile-navigation-open-btn');
        }
    } else if (elm.classList.contains('js-open-apply-modal')) {
        // open apply modal
        window.scrollTo(0,0);
        body.classList.add('no-scroll');
        applyModal.classList.add('open');
    } else if (elm.classList.contains('js-close-apply-modal') || elm.parentElement && elm.parentElement.classList.contains('js-close-apply-modal')) {
        // close apply modal
        body.classList.remove('no-scroll');
        applyModal.classList.remove('open');
    } else if (elm.classList.contains('js-open-refer-modal')) {
        // open refer modal
        window.scrollTo(0,0);
        body.classList.add('no-scroll');
        referModal.classList.add('open');
    } else if (elm.classList.contains('js-close-refer-modal') || elm.parentElement && elm.parentElement.classList.contains('js-close-refer-modal')) {
        // close refer modal
        body.classList.remove('no-scroll');
        referModal.classList.remove('open');
    } else if (elm.classList.contains('next-step-btn')) {
        // open second step refer modal
        const firstStep = document.getElementById('firstStep');
        const secondStep = document.getElementById('secondStep');
        firstStep.classList.remove('open');
        secondStep.classList.add('open');
    }
}, false);
